
import '../sass/project.scss';
/* Project specific Javascript goes here. */


import '../js/components/scroll-to-top';
import '../js/components/news-index-load-more';
import '../js/components/news-alert-bar';
import '../js/components/multi-carousel';
import '../js/components/gemfair-way-up-next';
import '../js/components/sideways-accordion';



function openDocument(title, href) {
    // TODO: Send to Analytics when set up
    // ga('send', 'event','click','Open ' + title);
    window.open(href, '_blank');
}

window.openDocument = openDocument;
