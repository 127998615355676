const sidewaysAccordion = document.querySelectorAll(".sideways-expanding-content");

sidewaysAccordion.forEach((accordion) => {

    const cards = accordion.querySelectorAll(".card")

    // helps to find the max height of the cards
    let maxHeight = 0;

    // Add click event to each card
    cards.forEach((card) => {

        console.log(`card: ${card}`);

        card.addEventListener("click", () => {

            // Update classes
            cards.forEach((element) => {
                element.classList.remove("expand", "unset")
                element.classList.add('small')
            })
            card.classList.remove("small")
            card.classList.add('expand')
        });

    });

    // Open each card get the content height
    cards.forEach((card, i) => {

        card.click();
        maxHeight = Math.max(maxHeight, card.querySelector(".card-text").clientHeight + card.querySelector(".card-head").clientHeight);

    });

    // Set the height of each card to the max height
    cards.forEach((card) => {
        card.style.height = `${maxHeight + 150}px`;
    });

    // Open the first card
    cards?.[0]?.click();

    // Remove the "uninitialized" class
    accordion.classList.remove("uninitialized");
});


