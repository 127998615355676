


document.querySelectorAll("[data-load-more-news]").forEach((btn)=>{
  btn.onclick = (e) =>{
    // get url for next page from data property
    const nextUrl = btn.dataset.loadMoreResults;

    // get page details from data properties
    const nextPage = parseInt(btn.dataset.nextPage);
    const numPages = parseInt(btn.dataset.numPages);
    const perPage = parseInt(btn.dataset.perPage);

    const fullUrl = `${nextUrl}?resultsOnly=true&page=${nextPage}&perPage=${perPage}`;
    // get html from url
    fetch(fullUrl).then(async (res)=>{

      // Append res text to container
      document.getElementById("newsIndexMoreResults").innerHTML += await res.text()

      // increase next page count
      btn.dataset.nextPage = nextPage + 1;

      // Remove the button if we're going beyond the number of pages
      if(btn.dataset.nextPage > numPages) btn.remove();

    }).catch((err)=>{
      console.warn(`Couldn't fetch from ${fullUrl}`);
    })
  };
});
