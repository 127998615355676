var newsCarouselEl = document.querySelector("#newsAlertBar");

function getCookie (name) {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

if (newsCarouselEl) {
   var isCookieHidden = getCookie("hide-news-carousel");
    if (isCookieHidden) {
        newsCarouselEl.style.display = 'none';
    } else {
        newsCarouselEl.style.display = 'block';
    }
}

function hideLatestNewsCarousel() {
    if(newsCarouselEl) {
        newsCarouselEl.style.display = 'none';
        document.cookie = 'hide-news-carousel=true; path=/; max-age=86400';
    }
}

window.hideLatestNewsCarousel = hideLatestNewsCarousel
