// Allows links outside of a tab to control a tabbed area

let tab_targets =  document.querySelectorAll("[data-bs-tab-target]");

tab_targets.forEach((btn)=>{
  const targetTabsId = btn.dataset.bsTabsTarget;
  const targetTabsElement = document.querySelector(targetTabsId);
  const targetTabId = btn.dataset.bsTabTarget;
  const targetTabElement = targetTabsElement.querySelector(`[data-bs-target='${targetTabId}']`);

  const tabInstance = bootstrap.Tab.getOrCreateInstance(targetTabElement);


  btn.addEventListener('click', (e)=>{
    e.preventDefault(); // Prevent click event
    tabInstance.show(); // Show tab
    targetTabsElement.parentElement.scrollIntoView(); // Scroll to top of sections area
  });

});
