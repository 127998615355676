const scrollToTop = document.querySelector('#scrollToTop');

window.addEventListener('scroll', function () {

    if (scrollToTop) {
        const top = window.pageYOffset || document.documentElement.scrollTop

        if (top > window.innerHeight + 100) {
            scrollToTop.classList.add('active');
        } else {
            scrollToTop.classList.remove('active');
        }
    };
});