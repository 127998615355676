// Get all the carousels on the page
let carousels = document.querySelectorAll('.carousel.carousel-multi');

// Go through each

console.log(window.innerWidth);

if (window.innerWidth > 768){

  carousels.forEach((c) => {

    const minPerSlide = 2;

    // Get every carousel item in the carousel
    let items = c.querySelectorAll('.carousel-item')

    // Clone slides to contain multiple slides
    items.forEach((el) => {
      // number of slides per carousel-item

      let next = el.nextElementSibling;

      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0];
        }
        let cloneChild = next.cloneNode(true);
        el.appendChild(cloneChild.children[0]);
        next = next.nextElementSibling;
      }
    });

    let maxHeight = 0;

    // Get height of every slide and work out the tallest
    items.forEach((el) => {
      if (el.offsetHeight > maxHeight) maxHeight = el.offsetHeight;

      el.querySelectorAll(
        '.carousel-item__wrapper'
      ).forEach(
        (w)=> {
          if (w.offsetHeight > maxHeight) maxHeight = w.offsetHeight;
        }
      );

    });

    // Apply the max height to all slides
    items.forEach((el) => {
      el.querySelectorAll(
        '.carousel-item__wrapper'
      ).forEach(
        (w)=> {w.style.width = `${100/minPerSlide}%`}
      );

      // el.style.height = `${maxHeight}px`;
    });

  });

};
